<template>
	<div id="BroadSide" :style="'right:' + RightSty + 'px'">
		<div class="switch" @click="Switch = !Switch">
			<van-icon :name="IconName" color="#000" size="16" style="font-weight:900" />
			<p>更<br>多<br>福<br>利</p>
		</div>
		<div class="content">
			<div class="Red_env">
				<img src="@/assets/images/BroadSide/Redenv.png" alt="" @click="Changeredshow">
				<span>红包</span>
			</div>
			<div class="Red_env">
				<img src="@/assets/images/Nav/10001.png" alt="" @click="chong()">
				<span>充值</span>
			</div>
			<div class="Red_env">
				<img src="@/assets/images/Nav/10002.png" alt="" @click="bei()">
				<span>背包</span>
			</div>
			<div class="Red_env">
				<img src="@/assets/images/Nav/10003.png" alt="" @click="kefus()">
				<span>客服</span>
			</div>
			<div class="Red_env">
				<img src="@/assets/images/Nav/10008.png" alt="" @click="RouteJump()">
				<span>群聊</span>
			</div>
		</div>
		
		<van-overlay :show="redshow" :lock-scroll="false" @click="redshow = false" >
			<div class="wrapper">
				<div class="red_box" @click.stop>
					<van-notice-bar background="rgba(0,0,0,0.1)" color="#fff" scrollable :text="site_bulletin"
						:key="key" />
					<!-- <div class="qq">加群<span>88888888</span>,不定期发放红包福利</div> -->
					<input type="text" placeholder="请输入红包口令" v-model="Red_key">
					<div class="red_open" @click="Exactly('2')">
						<img src="@/assets/images/BroadSide/open.png" alt="">
					</div>
					<div class="red_tit"><img src="@/assets/images/BroadSide/red_tit.png" alt=""></div>
					<div class="red_activity">
						<div class="red_item" v-for="(item,index) in Red_data" :key="index">
							<div class="red_item_top">
								<div class="red_img">
									<p>剩余数量</p>
									<span>{{item.remainder}}</span>
								</div>
								<div class="red_data">
									<b>{{item.title}}</b>
									<p>参与条件:{{item.briefe}}</p>
									<span>结束时间:{{item.end_time}}</span>
								</div>
							</div>
							<div class="red_btn" @click="Exactly('1',item.id)">抢红包</div>
						</div>
					</div>
					<div class="red_rule">口令红包规则</div>
				</div>
			</div>
		</van-overlay>

		<van-dialog v-model="dialog" :showConfirmButton="false">
			<div class="genus">
				<div class="amount">{{bean}}</div>
				<div class="genus_btn" @click="dialog = false">确认</div>
			</div>
		</van-dialog>

	</div>
</template>

<script>
	import { Red, Red_open, ApiInfo } from '@/network/api.js'
	export default {
		name: 'BroadSide',
		data() {
			return {
				Switch: true, //切换
				RightSty: 0, //偏移值
				IconName: 'arrow', //vant icon name
				redshow: false, //红包内容显示
				site_bulletin:'',//公告
				key: 0,
				dialog: false, //dialog显示隐藏
				boom: new Audio(require('@/assets/audio/boom.aac')), //红包声音
				Red_data:[],//红包列表
				bean:0,//红包
				Red_key:'',//红包口令
			}
		},

		created() {
			this.GetRed()
			this.GetApiInfo()
		},

		methods: {
			kefus(){
				window.open('https://tb.53kf.com/code/client/4ee32a69e6b55ca472ef34b644ccd7418/1')
			},
			//获取站点信息
			GetApiInfo(){
				ApiInfo().then((res) => {
					// console.log(res.data.data.site_bulletin)
					this.site_bulletin = res.data.data.site_bulletin
				})
			},

			// 获取红包列表
			GetRed(){
				Red().then((res) => {
					// console.log(res.data.data)
					this.Red_data = res.data.data
				})
			},

			//红包内容显示
			Changeredshow() {
				this.key += 1
				this.redshow = true
			},
			chong(){
				if(localStorage.getItem('token')){
					this.RouteJump('/Recharge')
				}else{
					this.$notify({
						type: "danger",
						message: "请登录后充值",
					});
				}
			},
			bei(){
				if(localStorage.getItem('token')){
					this.RouteJump('/Warehouse')
				}else{
					this.$notify({
						type: "danger",
						message: "请登录后查看背包",
					});
				}
			},
			RouteJump(str) {
			if (str == this.$route.fullPath) {
				this.$router.go(0);
			} else {
				this.$router.push(str);
			}
			},
			//开启红包
			Exactly(v,id) {
				if(v == '1'){
					Red_open(v,id).then((res) => {
						this.bean = res.data.data.bean
						this.boom.play()
						this.dialog = true

					})
				}else{
					Red_open(v,'',this.Red_key).then((res) => {
						this.bean = res.data.data.bean
						this.boom.play()
						this.dialog = true
					})

				}

			}

		},

		watch: {
			//切换
			Switch(v) {
				if (v == true) {
					this.RightSty = 0
					this.IconName = 'arrow'
				} else {
					this.RightSty = -50
					this.IconName = 'arrow-left'
				}
			}
		}
	}
</script>

<style lang="scss">
#BroadSide {
		position: fixed;
		right: 0;
		top: 45%;
		z-index: 10;
		display: flex;
		align-items: center;
		transition: .3s;
		
		.switch {
			width: 20px;
			height: 80px;
			background: rgb(255, 250, 2);
			border-radius: 3px 0 0 3px;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			font-weight: 900;
			display: none;
			p{
				width: 100%;
				text-align: center;
				font-size: 8px !important;
				margin-top: -0.3rem;
			}
		}

		.content {
			width: 30px;
			background: rgba($color: #000000, $alpha: 0.3);
			border-radius: 6px 0 0 6px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 10px;
			padding-right: 0;
			justify-content: space-around;
			/*justify-content: center;*/
			border: 1px solid rgb(255, 250, 2);
			display: none;
			.Red_env {
				width: 30px;
				margin-left: 2px;
				margin-top: 10px;
				color: white;
				span{
					width: 100%;
				}
				img {
					width: 20px;
					height: 20px;
					display: block;
				}
			}
			.Red_env:nth-child(1){
				margin-top: 0px !important;
				
			}
			.Red_env:nth-child(2){
				img {
					width: 20px;
					height: 20px;
				}
			}
			.Red_env:nth-child(3){
				img {
					width: 20px;
					height: 20px;
				}
			}
			.Red_env:nth-child(4){
				img {
					width: 20px;
					height: 20px;
				}
			}
			.Red_env:nth-child(5){
				img {
					width: 20px;
					height: 20px;
				}
			}
		}

		.van-overlay {
			.wrapper {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;

				.red_box {
					width: 100%;
					height: 250px;
					background: url('../../assets/images/q2/sidle/redbac.png') no-repeat top;
					background-size: 100% 100%;
					border-radius: 6px;
					position: relative;

					&::after {
						display: block;
						content: '';
						width: 50px;
						height: 200px;
						background: url(../../assets/images/BroadSide/decorateleft.png) no-repeat top;
						background-size: contain;
						position: absolute;
						left: -10px;
						top: -15px;
					}

					&::before {
						display: block;
						content: '';
						width: 50px;
						height: 200px;
						background: url(../../assets/images/BroadSide/decorateright.png) no-repeat top;
						background-size: contain;
						position: absolute;
						right: -10px;
						top: -15px;
					}

					.van-notice-bar {
						height: 37px;
						width: 30%;
						border-radius: 10px;
						margin: 10px auto;
						font-size: 12px;
						padding-left: 30px;
					}

					.qq {
						text-align: center;
						color: #fff;
						font-size: 14px;

						span {
							color: #fcb744;
							font-weight: bold;
						}
					}

					input {
						display: block;
						margin: 10px auto;
						width: 140px;
						height: 28px;
						border-radius: 6px;
						border: none;
						background: #fffad8;
						padding: 0 10px;
						box-sizing: border-box;
						text-align: center;
						font-size: 14px;
						color: #dbb389;

					}

					input::-webkit-input-placeholder {
						color: #dbb389;
					}

					input::-ms-input-placeholder {
						color: #dbb389;
					}

					input::-moz-input-placeholder {
						color: #dbb389;
					}

					.red_open {
						width: 40px;
						height: 40px;
						margin: 0 auto;
						background: #fcb744;
						border-radius: 50%;
						border: 4px solid #fca714;
						box-sizing: border-box;
						display: flex;
						justify-content: center;
						align-items: center;

						img {
							max-width: 70%;
							max-height: 70%;
							vertical-align: middle;
						}
					}

					.red_tit {
						text-align: center;
						height: 12px;
						margin: 10px auto;

						img {
							height: 100%;
						}
					}

					.red_activity {
						width: 100%;
						height: 180px;
						overflow-y: auto;

						.red_item {
							width: 94%;
							margin: 0 auto 10px;
							background: #ad252c;
							border-radius: 4px;
							padding: 10px;
							box-sizing: border-box;

							.red_item_top {
								width: 100%;
								height: 70px;
								display: flex;
								justify-content: space-between;

								.red_img {
									width: 70px;
									height: 70px;
									background: url(../../assets/images/BroadSide/activity_img.png) no-repeat center;
									background-size: contain;
									padding-top: 36px;
									box-sizing: border-box;
									text-align: center;
									color: #ff8975;
									font-size: 12px;

									span {
										color: #f5ba7f;
									}
								}

								.red_data {
									width: 150px;
									color: #ff8975;
									font-size: 12px;

									b {
										font-size: 14px;

									}

									p {
										margin-bottom: 2px;
									}

									span {
										font-size: 10px;
									}
								}
							}

							.red_btn {
								width: 100%;
								height: 30px;
								margin-top: 5px;
								background: #bd3840;
								border-radius: 20px;
								text-align: center;
								line-height: 30px;
								color: #ffc700;
								font-size: 14px;
								font-weight: bold;
							}
						}
					}

					.red_rule {
						text-align: center;
						margin-top: 10px;
						color: #feb5a3;
						font-size: 12px;
					}

				}
			}
		}

		.van-dialog {
			z-index: 10002 !important;
			width: 300px;
			background: none;

			.genus {
				width: 100%;
				height: 500px;
				background: url(../../assets/images/BroadSide/genus.png) no-repeat center;
				background-size: contain;
				padding-top: 400px;

				.amount {
					width: 160px;
					margin: 0 auto;
					height: 90px;
					font-size: 42px;
					font-weight: bold;
					display: flex;
					justify-content: center;
					align-items: center;
					color: transparent;
					color: #ffcf72;
					text-shadow: 1px 1px 2px #FF0000;
				}
				.genus_btn{
					width: 100px;
					height: 30px;
					text-align: center;
					line-height: 32px;
					font-size: 20px;
					font-weight: bold;
					margin: 0 auto;
					color: #ed3e26;
					border-radius: 20px;
					background-image: linear-gradient(#ebfd01, #fbd31e);
				}
			}
		}


	}
</style>

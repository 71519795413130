<template>
	<div id="swiper">
		<swiper v-if="false" :options="swiperOption" dir="rtl" class="swiper-no-swiping">
			<swiper-slide class="swiper-slide" v-for="(item,index) in carouselArr" :key="index">
				<a :href="item | ToRoute" class="swiper-slide_box">
					<img v-show="item.type == 3" class="Vsicon" src="@/assets/images/public/Vsicon.png" alt="">
					<div class="right">
						<div class="pic">
							<img :src="item.user.avatar" alt="">
						</div>
						<p>{{item.user.name | StrIntercept}}</p>
					</div>

					<div class="left">
						<div class="pic_data">
							<p dir="ltr">{{item.box_name | StrInterceptT}}</p>
							<span dir="ltr">{{item.name | StrInterceptS}}</span>
						</div>
						<div class="pic" :style="{'background-image': 'url('+item.lv_bg_image+')'}">
							<img :src="item.cover" alt="">
						</div>
					</div>
				</a>
			</swiper-slide>
		</swiper>

		<vue-seamless-scroll :data="carouselArr" :class-option="classOption" class="warp ">
			<ul class="ul-item">
				<div :style="{'background-image':`url(@/assets/images/Header/10005.png)` }" ></div>
				<li class="li-item " v-for="(item, index) in carouselArr" style="background-size:100% 100%" :style="{  'background-image': `url(${require(`../../assets/images/985/home/${item.lv}.png`)})`}">
					<a :href="item | ToRoute" class="warp-item-a">
						<img v-show="item.type == 3"
								 class="Vsicon" src="@/assets/images/public/Vsicon.png" alt="">
						<div class="warp-item-a-btn">
							<div class="pic" style="background-size: 100% 100%;"
							:style="{'background-image': 'url('+item.lv_bg_image+')'}">
								
								<img width="60px" height="45px" :src="item.cover" alt="">
							</div>
							<div class="itemname">
								<!-- <p style="color: red;">{{item.box_name | StrInterceptT}}</p> -->
								<span style="color:#fff;font-size:10px">{{item.name | StrInterceptS}}</span>
							</div>
						</div>
<!--						<div class="">-->
<!--							<div class="warp-item-a-img">-->
<!--								<img :src="item.user.avatar" alt="">-->
<!--							</div>-->
<!--							<p>{{item.user.name | StrIntercept}}</p>-->
<!--						</div>-->
					</a>
				</li>
			</ul>
		</vue-seamless-scroll>

	</div>
</template>

<script>
	import {
		Swiper,
		SwiperSlide
	} from 'vue-awesome-swiper'
	import 'swiper/css/swiper.css'
	import {
		UnpackingRecord
	} from '@/network/api.js'
import vueSeamlessScroll from 'vue-seamless-scroll'
	export default{
		name:'headerSwiper',
		data(){
			return {
				classOption: {
					limitMoveNum: 1,
					direction: 3,
				},
				carouselArr:[],//历史物品掉落记录
				//历史物品type
				// 'type' => [
				//     0 => '其他',
				//     1 => '开箱',
				//     2 => '福利箱',
				//     3 => '盲盒对战',
				//     4 => '幸运开箱',
				//     5 => '饰品商城',
				//     6 => '夺宝'
				// ]
				swiperOption: {
					// 自动轮播
					autoplay: {
						delay: 5000
					},
					//开启循环模式
					loop: true,
					// 网格分布一屏两个及走两个
					slidesPerView: 2,
					slidesPerGroup : 2,

				}
			}
		},
		filters:{
			StrIntercept(val){
				return val.length > 4 ?  '...' + val.substr(0, 3) : val
			},
			StrInterceptT(val){
				return val.length > 4 ?  val.substr(0, 4) + '...'  : val
			},
			StrInterceptS(val){
				return val.length > 7 ?  val.substr(0, 6) + '...'  : val
			},
			//路由过滤
			ToRoute(v){
				if(v.type == '1'){
					return '/Open?id=' + v.box_id
				}else if(v.type == '2'){
					return 'Activity'
				}else if(v.type == '3'){
					return '/Battle'
				}else if(v.type == '4'){
					return '/Lucky'
				}else if(v.type == '5'){
					return '/Mall'
				}
			}
		},

		created() {
			this.GetUnpackingRecord()
			this.TenSeconds()
		},

		methods:{
			//开奖记录
			GetUnpackingRecord() {
				UnpackingRecord().then((res) => {
					this.carouselArr = res.data.data.historylist
					// console.log(this.carouselArr);
					// console.log(res.data.data.historylist)
				})
			},
			//定时刷新
			TenSeconds() {
				setInterval(() => {
					this.GetUnpackingRecord()
				}, 10000)
			}
		},
		components: {
			Swiper,
			SwiperSlide,
			vueSeamlessScroll
		}
	}
</script>

<style lang="scss">

	.Vsicon{
		width: 15px;
		position: absolute;
		right: 0;
		top: 2px;
		// background: ;
	}
.warp {
	width: 100%;
	/*margin: 10px auto;*/
	overflow: hidden;
	/*height: 120px;*/
	&-item-a{
		display: flex;
		justify-content: space-around;
		align-items: center;
		position: relative;
		width: 100%;
		p {
			width: 100%;
				margin: 0;
				font-size: 10px;
				color: #ffba2e;
				text-align: center;
			}
		&-img{
			width: 30px;
			border-radius: 50%;
			overflow: hidden;
			& img{
				width: 100%;
			}
		}
		&-btn{
			/*display: flex;*/
		}
	}
	& .li-item {
		width: 100px;
		background: rgba($color: #121319, $alpha: .8);
		// border: 1px solid rgba($color: #242632, $alpha: 1);
		padding: 10px 0;
		height: 50px;
		margin:  0 5px;
		background-size: 100% 100%;
		/*border-radius: 4px;*/
		.itemname{
			width: 100%;
		}
	}
	ul {
		list-style: none;
		padding: 0;
		margin: 0 auto;

		&.ul-item {
			display: flex;
			margin-top: 10px;
		}
	}
}
	#swiper{
		width: 100%;
		/*height: 100px;*/
		padding-bottom: 4px;
		box-sizing: border-box;
		// background: rgba($color: #121319, $alpha: .8) ;
		// border-bottom: 1px solid #090b10;
		box-sizing: border-box;
		.swiper-container{
			width: 100%;
			height: 100%;
			.swiper-slide{
				width: 50% !important;
				height: 100%;
				padding:8px 4px;
				box-sizing: border-box;
				.swiper-slide_box{
					display: block;
					width: 100%;
					height: 100%;
					background: rgba(0, 0, 0, 0.2);
					border: 1px solid rgba($color: #242632 , $alpha: 1);
					border-radius: 4px;
					padding: 0 5px;
					box-sizing: border-box;
					display: flex;
					justify-content: space-between;
					position: relative;
					.Vsicon{
						width: 15px;
						position: absolute;
						right: 0;
						top: 2px;
					}
					.left{
						width: 70%;
						height: 100%;
						padding: 0 2px;
						box-sizing: border-box;
						display: flex;
						justify-content: space-between;
						align-items: center;
						.pic{
							width: 40px;
							height: 40px;
							background-size: 100% 100% !important;
							background-position: center !important;
							background-repeat: no-repeat !important;
							display: flex;
							justify-content: center;
							align-items: center;
							img{
								vertical-align: middle;
								max-width: 100%;
								max-height: 100%;
							}
						}
						.pic_data{
							width: 60%;
							height: 35px;
							padding-left: 2px;
							box-sizing: border-box;
							p{
								width: 100%;
								height: 20px;
								color: #d42d4f;
								font-size: 12px;
								font-weight: bold;
								white-space: nowrap;
								overflow: hidden;
								line-height: 20px;
								margin: 0;
							}
							span{
								display: block;
								width: 100%;
								height: 15px;
								font-size: 10px;
								color: #fff;
							}
						}
					}
					.right{
						width: 30%;
						.pic{
							width: 25px;
							height: 25px;
							border-radius: 50%;
							overflow: hidden;
							border: 1px solid #ffba2e;
							box-sizing: border-box;
							margin: 8px auto 5px;
							img{
								width: 100%;
								height: 100%;
							}
						}
						p{
							margin: 0;
							font-size: 10px;
							color: #ffba2e;
							text-align: center;
						}
					}
				}
			}
		}
	}
</style>
